<template>
  <div class="platform page">
    <el-row class="flex platform_content">
      <el-col
        :span="8"
        class="image animation-bounceInRight"
        v-for="item in items"
        :key="item.icon"
        :style="{ backgroundImage: 'url(' + item.src + ')' }"
      >
        <div class="top">
          <h3>{{ item.title }}</h3>
          <span>{{ item.link }}</span>
        </div>
        <div class="desc">
          {{ item.desc }}
        </div>
        <div class="footerbtn">
          <button class="zhuce1" v-show="item.icon == 1" @click="bindGoLink(item.icon)">注册</button>
          <button class="denglu1" v-show="item.icon == 1" @click="bindGoLink(item.icon)">登录</button>
          <p class="denglu2" v-show="item.icon == 2" @click="bindGoLink(item.icon)">登录</p>
          <p class="denglu3" v-show="item.icon == 3" @click="bindGoLink(item.icon)">进入网站</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "platform",
  data() {
    return {
      curtab: 0,
      items: [
        {
          icon: 1,
          src: require("@/assets/images/platform/6.png"),
          choosesrc: require("@/assets/images/platform/1_active.png"),
          active: false,
          title: "中集环控云平台",
          link: "y.ccsc58.com",
          //desc: "平台介绍文案平台介绍,文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介,绍文案平台介绍文,案平台介绍文案平台介,绍文案平台介绍文案平台介绍文案。 平台介绍文案平台介绍文案平台介绍文,案平台介绍文案平台介绍文案平台介绍文案平台介绍文,案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文,案平台介绍文案平台介,绍文案平台介绍文案平台介绍文案。",
        },
        {
          icon: 2,
          src: require("@/assets/images/platform/5.png"),
          choosesrc: require("@/assets/images/platform/2_active.png"),
          active: false,
          title: "中集在线",
          link: "t.ccsc58.com",
         // desc: "平台介绍文案平台介绍,文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介,绍文案平台介绍文,案平台介绍文案平台介,绍文案平台介绍文案平台介绍文案。 平台介绍文案平台介绍文案平台介绍文,案平台介绍文案平台介绍文案平台介绍文案平台介绍文,案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文,案平台介绍文案平台介,绍文案平台介绍文案平台介绍文案。",
        },
        {
          icon: 3,
          src: require("@/assets/images/platform/4.png"),
          choosesrc: require("@/assets/images/platform/3_active.png"),
          active: false,
          title: "冷库助手云平台",
          link: "cold.ccsc58.com",
          //desc: "平台介绍文案平台介绍,文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介,绍文案平台介绍文,案平台介绍文案平台介,绍文案平台介绍文案平台介绍文案。 平台介绍文案平台介绍文案平台介绍文,案平台介绍文案平台介绍文案平台介绍文案平台介绍文,案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文案平台介绍文,案平台介绍文案平台介,绍文案平台介绍文案平台介绍文案。",
        },
      ],
    };
  },
  activated() {
    // 进来的时候调用添加
  },
  beforeDestroy() {
    this.clearBodyBackGround();
  },
  watch: {
    $route: {
      immediate: true, // watch中的监听，第一次创建的时候，不会立即执行，加上这个参数，会立即执行
      handler(to, from) {
        console.log(to, from);
        if (to.path == "") {
          // this.setBodyBackGround();
        }
        if (to.path == "/platform/index") {
          // this.setBodyBackGround();
        } else {
          // this.clearBodyBackGround();
        }
      },
    },
  },
  methods: {
    bindGoLink(e) {
      if (e == 2) {
        // window.location.href = "https://t.ccsc58.com/";
        window.open("https://t.ccsc58.com/", "_blank");
      } else if (e == 1) {
        window.open("http://y.ccsc58.com//#/Login", "_blank");
      } else if (e == 3) {
        window.open("http://cold.ccsc58.com//#/Login", "_blank");
        // window.location.href = "http://cold.ccsc58.com/#/Login";
      }
    },
    selectStyle(item) {
      console.log("hover", item);
      var _this = this;
      this.$nextTick(function () {
        // this.items.forEach(function (item) {
        //   _this.$set(item, "active", false);
        // });
        this.$set(item, "active", true);
        _this.curtab = item.icon;
        if (item.icon == 1) {
        } else if (item.icon == 2) {
        } else if (item.icon == 3) {
        } else {
        }
      });
    },
    outStyle(item) {
      console.log("move", item);
      this.$set(item, "active", false);
      this.curtab = 0;
      // this.setBodyBackGround();
    },
    bindplatformhref(e) {
      this.clearBodyBackGround();
      if (e == 1) {
        this.$router.push({
          path: "/platform/coldchain/index",
        });
      } else if (e == 2) {
        this.$router.push({
          path: "/platform/foodfresh/index",
        });
      } else if (e == 3) {
        this.$router.push({
          path: "/platform/breed/index",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.platform {
  // height: calc(100vh-80px);
  height: 100%;
  // margin-top: 80px;
  .platform_content {
    height: 100%;
    .top {
      text-align: center;
      margin: 80px auto 30px;
      h3 {
        font-size: 42px;
        font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
        font-weight: 800;
        color: #ffffff;
        line-height: 59px;
      }
      span {
        font-size: 16px;
        font-family: zcoolwenyiti-Regular, zcoolwenyiti;
        font-weight: 400;
        color: #ffffff;
        line-height: 42px;
        letter-spacing: 4px;
        margin: 15px auto 30px;
      }
    }
    .desc {
      width: 420px;
      font-size: 15px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 33px;
      letter-spacing: 1px;
      margin: 45px auto 85px;
      text-indent: 40px;
    }
    .footerbtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 420px;

      .zhuce1,
      .denglu1 {
        width: 164px;
        height: 60px;
        line-height: 60px;
        background: #ffffff;
        color: #fda805;
        font-size: 24px;
        font-weight: bold;
        border-radius: 15px;
        text-align: center;
        letter-spacing: 5px;
        // background: url("../../assets/images/platform/btn1.png") no-repeat;
        // background-size: 100% 100%;
      }
      .denglu1 {
        margin-left: 15px;
      }

      .denglu2 {
        width: 400px;
        height: 60px;
        line-height: 60px;
        // background: url("../../assets/images/platform/btn3.png") no-repeat;
        // background-size: 100% 100%;
        background: #ffffff;
        color: #0F70F5;
        font-size: 24px;
        letter-spacing: 5px;
        font-weight: bold;
        border-radius: 15px;
        text-align: center;
      }
      .denglu3 {
        width: 400px;
        height: 60px;
        line-height: 60px;
        // background: url("../../assets/images/platform/btn3.png") no-repeat;
        // background-size: 100% 100%;
        background: #ffffff;
        color: #53b26d;
        font-size: 24px;
        letter-spacing: 5px;
        font-weight: bold;
        border-radius: 15px;
        text-align: center;
      }
    }
  }
}
.nav {
  padding-top: 120px;
  padding-bottom: 15px;
}

.image {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.noimage {
  height: 100%;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.active {
  height: 100%;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 0.5s;
  }
}
</style>